.border-warning {
  border-color: #ff5349 !important;
}

#interactive.viewport canvas,
video {
  max-height: 350px;
  width: 100% !important;
  padding: 0;
  margin: 0;
}

.drawingBuffer {
  display: none;
}
